import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { selectUser } from 'store/slices/authSlice'
import routes from 'constants/routes'
import { useGameSettingsQuery } from 'store/services/api'
import Preloader from 'components/Preloader'
import Prize from 'components/prize/Prize'
import PrizesPage from './PrizesPage'
import Rules from 'components/Rules'
import dayjs from 'dayjs'


const MainPage = () => {
  const user = useSelector(selectUser)
  const { data: settings, isLoading: isSettingsLoading  } = useGameSettingsQuery()

  const dateStr = useMemo(
    () => {
      if (isSettingsLoading) return null
      return settings.schedule.length > 0 && dayjs(settings.schedule[0].date).tz('America/New_York').format('MMM Do, ha EST')
    },
    [settings, isSettingsLoading]
  )

  if (isSettingsLoading) return <Preloader />

  return (
    <Box>
      <Text size="title">
        Next game<br />
        {dateStr}
      </Text>
      <Box mt="20px">
        {settings.inprogress_prize && <Prize prize={settings.inprogress_prize} />}
      </Box>
      {user ? (
        <Flex gap="10px" mt="30px">
          <Button flex="1" as={Link} to={routes.game}>Game lobby</Button>
          <Button flex="1" variant="secondary" as={Link} to={routes.rules}>View Rules</Button>
        </Flex>
      ) : (
        <Flex gap="10px" mt="30px">
          <Button flex="1" as={Link} to={routes.signup}>Sign Up</Button>
          <Button flex="1" variant="secondary" as={Link} to={routes.signin}>Log In</Button>
        </Flex>
      )}
      
      <Box mt="50px">
        <PrizesPage />
      </Box>
      <Rules pt="20px" rules={settings.rules.simple_rules} title="Simple and Fun" />
      
    </Box>
  )
}

export default MainPage

